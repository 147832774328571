import React, { Component } from "react";

import Navbar from "./navbar";

export default class imprint extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render(props) {
    return (
      <>
        <Navbar
          lang={this.props.lang}
          text={"black"}
          handleLangHome={this.props.handleLang}
        />
        <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
          <div className="max-w-max lg:max-w-7xl mx-auto">
            <div className="relative">
              <svg
                className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-fgDefault-black"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
                />
              </svg>
              <svg
                className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-fgDefault-black"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
                />
              </svg>
              <div className="relative md:bg-white md:p-6">
                <div className="relative z-10 mb-12">
                  <div className="text-base max-w-prose lg:max-w-none">
                    <h2 className="inline-flex items-center px-3 py-0.5 my-4 rounded-full text-md font-medium bg-gray-200 text-fgDefault-black uppercase">
                      formgroup
                    </h2>

                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-fgDefault-black sm:text-3xl uppercase">
                      {this.props.lang === "de" ? "Impressum" : "Imprint"}
                    </p>
                  </div>
                </div>
                <div className="lg:grid lg:grid-cols-2 lg:gap-2">
                  <div className="prose prose-indigo prose-lg text-fgDefault-black lg:max-w-none">
                    <h2>
                      {this.props.lang === "de"
                        ? "Angaben gem. § 5 TMG"
                        : "Information pursuant to section 5 of the German Telemedia Act (TMG)"}
                    </h2>
                    <br />
                    <h2 className="font-bold">formgroup</h2>
                    <p className='pt-3'>
                      <br />
                      Hugo Schweers
                      <br />
                      Markt, 5<br />
                      46459 Rees
                    </p>
                    <br />
                    <h2>{this.props.lang === "de" ? "Kontakt" : "Contact"}</h2>
                    <p className='pt-3'>E-Mail: info@formgroup.io</p>
                  </div>
                  <div className="mt-6 prose prose-indigo prose-lg text-fgDefault-black lg:mt-0">
                    {this.props.lang === "de" ? (
                      <div>
                        <h1 className="text-xl">Datenschutzrichtlinie</h1>
                        <br/>
                        <p className='pt-3'>Gültig seit: 2023-11-24</p>
                        <p className='pt-3'>Aktualisiert am: 2023-11-24</p>
                        <br/>
                        <p className='pt-3'>
                          Diese Datenschutzrichtlinie erläutert die Richtlinien
                          von formgroup zur Erfassung und Verwendung der
                          Informationen, die wir sammeln, wenn Sie auf
                          www.agroform.de & www.formgroup.io (den „Dienst“) zugreifen. Diese
                          Datenschutzrichtlinie beschreibt Ihre
                          Datenschutzrechte und wie Sie durch Datenschutzgesetze
                          geschützt sind.
                        </p>
                        <p className='pt-3'>
                          Durch die Nutzung unseres Dienstes erklären Sie sich
                          mit der Erfassung und Nutzung Ihrer Daten in
                          Übereinstimmung mit dieser Datenschutzrichtlinie
                          einverstanden. Bitte greifen Sie nicht auf unseren
                          Dienst zu oder nutzen Sie ihn nicht, wenn Sie nicht
                          mit der Erfassung und Nutzung Ihrer Daten, wie in
                          dieser Datenschutzrichtlinie dargelegt, einverstanden
                          sind. Diese Datenschutzerklärung wurde mithilfe des
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://cookie-script.com/privacy-policy-generator"
                          >
                            CookieScript Datenschutzerklärungs-Generators
                          </a>
                          erstellt.
                        </p>
                        <p className='pt-3'>
                          formgroup ist berechtigt, diese Datenschutzerklärung
                          jederzeit zu ändern. Dies kann ohne vorherige
                          Ankündigung geschehen.{" "}
                        </p>
                        <p className='pt-3'>
                          formgroup wird die überarbeitete Datenschutzrichtlinie
                          auf der Website www.agroform.de & www.formgroup.io veröffentlichen.
                        </p>

                        <h3>
                          Erfassung und Verwendung Ihrer persönlichen Daten
                        </h3>
                        <h4>Welche Art von Daten wir sammeln</h4>
                        <p className='pt-3'>
                          Bei der Nutzung unseres Dienstes werden Sie
                          aufgefordert, uns personenbezogene Daten mitzuteilen,
                          um Sie zu kontaktieren oder zu identifizieren.
                          www.agroform.de & www.formgroup.io sammelt die folgenden Informationen:{" "}
                        </p>

                        <ul>
                          <li>Nutzungsdaten</li>
                          <li>E-Mail</li>
                        </ul>

                        <p className='pt-3'>Zu den Nutzungsdaten gehören die folgenden Daten:</p>
                        <ul>
                          <li>
                            Internet-Protokoll (IP)-Adresse der Computer, die
                            auf die Website zugreifen
                          </li>
                          <li>Website-Anfragen</li>
                          <li>Verweisende Webseiten</li>
                          <li>
                            Für den Zugriff auf die Website verwendeter Browser
                          </li>
                          <li>Uhrzeit und Datum des Zugriffs</li>
                        </ul>

                        <h4>Auf welche Weise wir Daten sammeln</h4>
                        <p className='pt-3'>
                          www.agroform.de & www.formgroup.io sammelt und erhält Daten von Ihnen auf
                          folgende Weise:{" "}
                        </p>
                        <ul>
                          <li>Wenn Sie mit unserem Dienst interagieren.</li>
                        </ul>
                        <p className='pt-3'>
                          Ihre Daten werden bis zu 30 Tag(e) nach Kündigung
                          Ihres Kontos gespeichert. Ihre Daten können in
                          Übereinstimmung mit den geltenden Gesetzen für längere
                          Zeiträume aufbewahrt werden, um Berichte oder
                          Aufzeichnungen zu erstellen. Daten, die Sie nicht
                          persönlich identifizieren, können auf unbestimmte Zeit
                          gespeichert werden.{" "}
                        </p>

                        <h4>Wozu wir Ihre Daten verwenden</h4>
                        <p className='pt-3'>
                          www.agroform.de & www.formgroup.io kann Ihre Daten für die folgenden
                          Zwecke verwenden:{" "}
                        </p>
                        <ul>
                          <li>
                            <b>Bereitstellung und Wartung unseres Dienstes,</b>
                            sowie die Überwachung der Nutzung unseres Dienstes.
                          </li>
                          <li>
                            <b>Um Sie zu kontaktieren.</b> formgroup kann Sie
                            per E-Mail, Telefon, SMS oder einer anderen Form der
                            elektronischen Kommunikation im Zusammenhang mit den
                            Funktionen, Produkten, Dienstleistungen oder
                            Sicherheitsupdates kontaktieren, wenn dies notwendig
                            oder angemessen ist.
                          </li>
                          <li>
                            <b>Um Sie</b> über Neuigkeiten, allgemeine
                            Informationen, Sonderangebote, neue Dienste und
                            Veranstaltungen zu informieren.
                          </li>
                        </ul>

                        <h4>Wie wir Ihre Daten verwenden</h4>
                        <p className='pt-3'>
                          formgroup kann Ihre Daten gegebenenfalls in den
                          folgenden Situationen weitergeben:{" "}
                        </p>
                        <ul>
                          <li>
                            <b>Mit Ihrer Zustimmung.</b> formgroup wird Ihre
                            Daten für jeden Zweck nur mit Ihrer ausdrücklichen
                            Zustimmung weitergeben.
                          </li>
                        </ul>

                        <h4>Freigabe durch Dritte</h4>
                        <p className='pt-3'>
                          Jede Drittpartei, an die wir Ihre Daten weitergeben,
                          muss den Zweck offenlegen, für den sie Ihre Daten zu
                          verwenden beabsichtigt. Sie dürfen Ihre Daten nur für
                          den Zeitraum aufbewahren, der bei der Anforderung oder
                          dem Erhalt der Daten angegeben wurde. Der
                          Drittanbieter darf Ihre persönlichen Daten nicht
                          weiter erfassen, verkaufen oder verwenden, es sei
                          denn, dies ist zur Erfüllung des angegebenen Zwecks
                          erforderlich.{" "}
                        </p>
                        <p className='pt-3'>
                          Ihre Daten können aus folgenden Gründen an Dritte
                          weitergegeben werden:
                        </p>
                        <ul>
                          <li>
                            <b>Analysedaten.</b> Ihre Daten können an
                            Online-Analyse-Tools weitergegeben werden, um den
                            Website-Verkehr zu verfolgen und zu analysieren.
                          </li>
                          <li>
                            <b>Schaltung gezielter Werbekampagnen.</b> formgroup
                            arbeitet mit Drittanbietern zusammen, um gezielte
                            Werbekampagnen zu schalten.
                          </li>
                        </ul>
                        <p className='pt-3'>
                          Wenn Sie sich dafür entscheiden, solche Daten während
                          der Registrierung oder anderweitig zur Verfügung zu
                          stellen, erteilen Sie formgroup die Erlaubnis, diese
                          Informationen in Übereinstimmung mit dieser
                          Datenschutzrichtlinie zu verwenden, weiterzugeben und
                          zu speichern.{" "}
                        </p>

                        <p className='pt-3'>
                          Ihre Daten können aus weiteren Gründen weitergegeben
                          werden, z. B:
                        </p>
                        <ul>
                          <li>
                            Einhaltung geltender Gesetze, Vorschriften oder
                            gerichtlicher Anordnungen.
                          </li>
                          <li>
                            Reagieren auf Behauptungen, dass Ihre Nutzung
                            unseres Dienstes die Rechte Dritter verletzt.
                          </li>
                          <li>
                            Durchsetzung von Vereinbarungen, die Sie mit uns
                            getroffen haben, einschließlich dieser
                            Datenschutzrichtlinie.
                          </li>
                        </ul>

                        <h4>Cookies</h4>
                        <p className='pt-3'>
                          Cookies sind kleine Textdateien, die von Websites, die
                          Sie besuchen, auf Ihrem Computer gespeichert werden.
                          Websites verwenden Cookies, um den Nutzern eine
                          effiziente Navigation zu ermöglichen und zu helfen,
                          bestimmte Funktionen auszuführen. Cookies, die für den
                          ordnungsgemäßen Betrieb der Website erforderlich sind,
                          dürfen auch ohne Ihre Zustimmung gesetzt werden. Alle
                          anderen Cookies müssen erst genehmigt werden, bevor
                          sie im Browser gesetzt werden können.{" "}
                        </p>
                        <ul>
                          <li>
                            <b>Unbedingt erforderliche Cookies.</b> Unbedingt
                            erforderliche Cookies ermöglichen wesentliche
                            Kernfunktionen der Website wie die Benutzeranmeldung
                            und die Kontoverwaltung. Ohne die unbedingt
                            erforderlichen Cookies kann die Website nicht
                            ordnungsgemäß verwendet werden.
                          </li>
                          <li>
                            <b>Performance-Cookies.</b> Performance-Cookies
                            sammeln Informationen darüber, wie Besucher eine
                            Webseite nutzen, z. B. Analyse-Cookies. Diese
                            Cookies können nicht verwendet werden, um einen
                            bestimmten Besucher direkt zu identifizieren.
                          </li>
                          <li>
                            <b>Targeting-Cookies.</b> Targeting-Cookies werden
                            verwendet, um Besucher zwischen verschiedenen
                            Websites zu identifizieren, z. B. Content-Partner,
                            Bannernetzwerke. Diese Cookies können von
                            Unternehmen verwendet werden, um ein Profil der
                            Besucherinteressen zu erstellen oder relevante
                            Anzeigen auf anderen Websites zu schalten.
                          </li>
                          <li>
                            <b>Funktionale Cookies.</b> Funktionale Cookies
                            werden verwendet, um Besucherinformationen auf der
                            Website zu speichern, z. Sprache, Zeitzone,
                            erweiterter Inhalte.
                          </li>
                          <li>
                            <b>Unklassifizierte Cookies.</b> Unklassifizierte
                            Cookies sind Cookies, die keiner der anderen
                            Kategorien angehören oder sich im Prozess der
                            Klassifizierung befinden.
                          </li>
                        </ul>

                        <div class="cookie-report-container">
                          <p data-cookiereport="true">
                            Sie können Ihre Zustimmung zur Cookie-Nutzung unten
                            ändern.{" "}
                          </p>
                          <script
                            type="text/javascript"
                            charset="UTF-8"
                            data-cookiescriptreport="report"
                            data-cs-lang="de"
                            src="//report.cookie-script.com/r/86b71eec273999a5dba7cce264c6d38d.js"
                          ></script>
                        </div>

                        <h4>Sicherheit</h4>
                        <p className='pt-3'>
                          Die Sicherheit Ihrer Daten ist für uns wichtig.
                          www.agroform.de & www.formgroup.io setzt eine Reihe von
                          Sicherheitsmaßnahmen ein, um den Missbrauch, den
                          Verlust oder die Veränderung der von Ihnen zur
                          Verfügung gestellten Daten zu verhindern. Da wir
                          jedoch die Sicherheit der von Ihnen bereitgestellten
                          Daten nicht garantieren können, erfolgt der Zugang zu
                          unserem Dienst auf Ihr eigenes Risiko.{" "}
                        </p>
                        <p className='pt-3'>
                          formgroup ist nicht verantwortlich für die Leistung
                          von Websites, die von Dritten betrieben werden, oder
                          für Ihre Interaktionen mit diesen. Wenn Sie diese
                          Website verlassen, empfehlen wir Ihnen, die
                          Datenschutzpraktiken anderer Websites, mit denen Sie
                          interagieren, zu überprüfen und die Angemessenheit
                          dieser Praktiken festzustellen.{" "}
                        </p>
                        <br/>
                        <h4>Kontakt</h4>
                        <p className='pt-3'>
                          Wenn Sie Fragen haben, kontaktieren Sie uns bitte über
                          eine der folgenden Kanäle:
                        </p>
                        <p className='pt-3'>Name: formgroup</p>
                        <p className='pt-3'>Adresse: Markt 5, 46459 Rees Germany</p>
                        <p className='pt-3'>E-Mail: info@formgroup.io</p>
                        <p className='pt-3'>Websites: www.agroform.de & www.formgroup.io</p>
                      </div>
                    ) : (
                      <div>
                        <h1 className="text-xl">Privacy Policy</h1>
                        <br/>
                        <p className='pt-3'>Effective date: 2023-11-24</p>
                        <p className='pt-3'>Updated on: 2023-11-24</p>
                        <br/>
                        <p className='pt-3'>
                          This Privacy Policy explains the policies of formgroup
                          on the collection and use of the information we
                          collect when you access www.agroform.de & www.formgroup.io (the
                          “Service”). This Privacy Policy describes your privacy
                          rights and how you are protected under privacy laws.
                        </p>
                        <p className='pt-3'>
                          By using our Service, you are consenting to the
                          collection and use of your information in accordance
                          with this Privacy Policy. Please do not access or use
                          our Service if you do not consent to the collection
                          and use of your information as outlined in this
                          Privacy Policy. This Privacy Policy has been created
                          with the help of{" "}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://cookie-script.com/privacy-policy-generator"
                          >
                            CookieScript Privacy Policy Generator
                          </a>
                          .
                        </p>
                        <p className='pt-3'>
                          formgroup is authorized to modify this Privacy Policy
                          at any time. This may occur without prior notice.
                        </p>
                        <p className='pt-3'>
                          formgroup will post the revised Privacy Policy on the
                          www.agroform.de & www.formgroup.io website
                        </p>

                        <h3>Collection and Use of Your Personal Information</h3>
                        <h4>Information We Collect</h4>
                        <p className='pt-3'>
                          When using our Service, you will be prompted to
                          provide us with personal information used to contact
                          or identify you. www.agroform.de & www.formgroup.io collects the
                          following information:{" "}
                        </p>

                        <ul>
                          <li>Usage Data</li>
                          <li>Email</li>
                        </ul>

                        <p className='pt-3'>Usage Data includes the following:</p>
                        <ul>
                          <li>
                            Internet Protocol (IP) address of computers
                            accessing the site
                          </li>
                          <li>Web page requests</li>
                          <li>Referring web pages</li>
                          <li>Browser used to access site</li>
                          <li>Time and date of access</li>
                        </ul>

                        <h4>How We Collect Information</h4>
                        <p className='pt-3'>
                          www.agroform.de & www.formgroup.io collects and receives information from
                          you in the following manner:{" "}
                        </p>
                        <ul>
                          <li>When you interact with our Service.</li>
                        </ul>
                        <p className='pt-3'>
                          Your information will be stored for up to 30 days
                          after it is no longer required to provide you the
                          services. Your information may be retained for longer
                          periods for reporting or record- keeping in accordance
                          with applicable laws. Information which does not
                          identify you personally may be stored indefinitely.{" "}
                        </p>

                        <h4>How We Use Your Information</h4>
                        <p className='pt-3'>
                          www.agroform.de & www.formgroup.io may use your information for the
                          following purposes:{" "}
                        </p>
                        <ul>
                          <li>
                            <b>Providing and maintaining our Service,</b> as
                            well as monitoring the usage of our Service.
                          </li>
                          <li>
                            <b>To contact you.</b> formgroup will contact you by
                            email, phone, SMS, or another form of electronic
                            communication related to the functions, products,
                            services, or security updates when necessary or
                            reasonable.
                          </li>
                          <li>
                            <b>To update you</b> with news, general information,
                            special offers, new services, and events.
                          </li>
                        </ul>

                        <h4>How We Share Your Information</h4>
                        <p className='pt-3'>
                          formgroup will share your information, when
                          applicable, in the following situations:{" "}
                        </p>
                        <ul>
                          <li>
                            <b>With your consent.</b> formgroup will share your
                            information for any purpose with your explicit
                            consent.
                          </li>
                        </ul>

                        <h4>Third-party Sharing</h4>
                        <p className='pt-3'>
                          Any third party we share your information with must
                          disclose the purpose for which they intend to use your
                          information. They must retain your information only
                          for the duration disclosed when requesting or
                          receiving said information. The third-party service
                          provider must not further collect, sell, or use your
                          personal information except as necessary to perform
                          the specified purpose.{" "}
                        </p>
                        <p className='pt-3'>
                          Your information may be shared to a third-party for
                          reasons including:
                        </p>
                        <ul>
                          <li>
                            <b>Analytics information.</b> Your information might
                            be shared with online analytics tools in order to
                            track and analyse website traffic.
                          </li>
                          <li>
                            <b>Improving targeted advertising campaigns.</b>{" "}
                            formgroup partners with third party service
                            providers to improve targeted advertising campaigns.
                          </li>
                        </ul>
                        <p className='pt-3'>
                          If you choose to provide such information during
                          registration or otherwise, you are giving formgroup
                          permission to use, share, and store that information
                          in a manner consistent with this Privacy Policy.{" "}
                        </p>

                        <p className='pt-3'>
                          Your information may be disclosed for additional
                          reasons, including:
                        </p>
                        <ul>
                          <li>
                            Complying with applicable laws, regulations, or
                            court orders.
                          </li>
                          <li>
                            Responding to claims that your use of our Service
                            violates third-party rights.
                          </li>
                          <li>
                            Enforcing agreements you make with us, including
                            this Privacy Policy.
                          </li>
                        </ul>

                        <h4>Cookies</h4>
                        <p className='pt-3'>
                          Cookies are small text files that are placed on your
                          computer by websites that you visit. Websites use
                          cookies to help users navigate efficiently and perform
                          certain functions. Cookies that are required for the
                          website to operate properly are allowed to be set
                          without your permission. All other cookies need to be
                          approved before they can be set in the browser.{" "}
                        </p>
                        <ul>
                          <li>
                            <b>Strictly necessary cookies.</b> Strictly
                            necessary cookies allow core website functionality
                            such as user login and account management. The
                            website cannot be used properly without strictly
                            necessary cookies.
                          </li>
                          <li>
                            <b>Performance cookies.</b> Performance cookies are
                            used to see how visitors use the website, eg.
                            analytics cookies. Those cookies cannot be used to
                            directly identify a certain visitor.
                          </li>
                          <li>
                            <b>Targeting cookies.</b> Targeting cookies are used
                            to identify visitors between different websites, eg.
                            content partners, banner networks. Those cookies may
                            be used by companies to build a profile of visitor
                            interests or show relevant ads on other websites.
                          </li>
                          <li>
                            <b>Functionality cookies.</b> Functionality cookies
                            are used to remember visitor information on the
                            website, eg. language, timezone, enhanced content.
                          </li>
                          <li>
                            <b>Unclassified cookies.</b> Unclassified cookies
                            are cookies that do not belong to any other category
                            or are in the process of categorization.
                          </li>
                        </ul>

                        <div class="cookie-report-container">
                          <p data-cookiereport="true">
                            You can change your consent to cookie usage below.{" "}
                          </p>
                          <script
                            type="text/javascript"
                            charset="UTF-8"
                            data-cookiescriptreport="report"
                            data-cs-lang="en"
                            src="//report.cookie-script.com/r/86b71eec273999a5dba7cce264c6d38d.js"
                          ></script>
                        </div>

                        <h4>Security</h4>
                        <p className='pt-3'>
                          Your information’s security is important to us.
                          www.agroform.de & www.formgroup.io utilizes a range of security measures
                          to prevent the misuse, loss, or alteration of the
                          information you have given us. However, because we
                          cannot guarantee the security of the information you
                          provide us, you must access our service at your own
                          risk.{" "}
                        </p>
                        <p className='pt-3'>
                          formgroup is not responsible for the performance of
                          websites operated by third parties or your
                          interactions with them. When you leave this website,
                          we recommend you review the privacy practices of other
                          websites you interact with and determine the adequacy
                          of those practices.{" "}
                        </p>
                        <br/>
                        <h4>Contact Us</h4>
                        <p className='pt-3'>
                          For any questions, please contact us through thex
                          following methods:
                        </p>
                        <p className='pt-3'>Name: formgroup</p>
                        <p className='pt-3'>Address: Markt 5, 46459 Rees Germany</p>
                        <p className='pt-3'>Email: info@formgroup.io</p>
                        <p className='pt-3'>Websites: www.agroform.de & www.formgroup.io</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
